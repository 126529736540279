<!--explain：;@author: cy; @version: 1.0; date: 2022/4/15;-->
<template>
  <div>
    <k-crud-page>
      <template #resultlist>
        <k-crud-table
          :total="cruddataMX.pagination.total"
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>消息配置列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['message.push.config.add'])"
                icon="mdi-plus"
                text="新增消息配置"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                maxwidth="700px"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form ref="msgForm">
                    <v-row>
                      <v-col :cols="6">
                        <k-select
                          v-model="cruddataMX.data.moduleUuid"
                          :items="moduleList"
                          label="业务模块"
                          :rules="[globalRules.empty]"
                          @change="moduleUuidChange"
                        />
                      </v-col>
                      <v-col :cols="6">
                        <rz-combobox
                          v-model="cruddataMX.data.itemUuids"
                          :items="moduleItemsList"
                          label="消息"
                          :rules="[globalRules.multiple]"
                        >
                          <template
                            #selection="{
                              attrs,
                              item,
                              parent,
                              selected,
                              index,
                            }"
                          >
                            <v-chip
                              v-if="item === Object(item) && index === 0"
                              v-bind="attrs"
                              color="primary"
                              :input-value="selected"
                              label
                              small
                            >
                              <span class="pr-2">
                                {{ item.text }}
                              </span>
                              <v-icon small @click="parent.selectItem(item)">
                                $delete
                              </v-icon>
                            </v-chip>
                            <span v-if="index === 1" class="primary--text">
                              +{{ cruddataMX.data.itemUuids.length - 1 }}
                            </span>
                          </template>
                        </rz-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="12">
                        <div class="d-flex">
                          <div
                            style="width: 100px"
                            class="text-right mr-2 mt-5"
                          >
                            <span class="error--text">*</span>
                            <span>推送对象:</span>
                          </div>
                          <div class="flex-grow-1 overflow-x-auto">
                            <div
                              v-for="item in roleList"
                              :key="item.id"
                              class="d-flex"
                            >
                              <v-checkbox
                                v-if="item.children && item.children.length"
                                v-model="item.checkAll"
                                class="mr-6 subtitle-1"
                                :indeterminate="roleItemIndeterminate(item)"
                                :label="item.name"
                                @change="roleChangeAll(item, $event)"
                              ></v-checkbox>
                              <template
                                v-if="item.children && item.children.length"
                              >
                                <v-checkbox
                                  v-for="sub in item.children"
                                  :key="sub.id"
                                  v-model="cruddataMX.data.roleUuids"
                                  class="mr-2"
                                  :rules="[globalRules.multiple]"
                                  :label="sub.name"
                                  :value="sub.id"
                                ></v-checkbox>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div
                            style="width: 100px"
                            class="text-right mt-5 mr-2"
                          >
                            <span class="error--text">*</span>
                            <span>推送目标:</span>
                          </div>

                          <div class="d-flex">
                            <v-checkbox
                              v-for="sub in dict.MessagePushTargetEnum"
                              :key="sub.code"
                              v-model="cruddataMX.data.targetCodes"
                              class="mr-2"
                              :rules="[globalRules.multiple]"
                              :label="sub.msg"
                              :value="sub.code"
                            ></v-checkbox>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #actions="{ item }">
            <k-table-warning-btn
              v-if="hasPermission(['message.push.config.edit'])"
              text="编辑"
              class="mx-4"
              @click="
                crudActionMX().editItem(item, () => onOpenEdit(item, '01'))
              "
            />
            <k-table-error-btn
              v-if="hasPermission(['message.push.config.delete'])"
              text="删除"
              @click="crudActionMX().deleteItem(item)"
            />
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {},

  data() {
    return {
      dict: {
        MessagePushTargetEnum: [],
      },
      moduleList: [],
      moduleItemsList: [], //消息
      roleList: [],
      crud: {
        name: '消息配置',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'msgForm',
        },
        default: {
          uuid: null,
          moduleUuid: '', //模块UUID [业务模块List接口]
          itemUuids: [], //消息 [模块分析项目List接口]
          roleUuids: [], //角色UUID [角色Tree接口]
          targetCodes: [], //推送目标code [枚举字典 MessagePushTargetEnum]
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false },
        { text: '业务模块', value: 'moduleName', sortable: false },
        { text: '消息', value: 'itemsName', sortable: false },
        { text: '推送对象', value: 'rolesName', sortable: false },
        { text: '推送目标', value: 'targetsName', sortable: false },
        { text: '操作', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {},
  created() {
    this.getDict();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.msgConfig.msgConfig.page,
        add: (params) => {
          params.itemUuids = params.itemUuids.map((val) => val.value);
          return this.$api.msgConfig.msgConfig.add(params);
        },
        edit: (params) => {
          params.itemUuids = params.itemUuids.map((val) => val.value);
          return this.$api.msgConfig.msgConfig.update(params);
        },
        delete: this.$api.msgConfig.msgConfig.del,
      },
      {},
      {
        add: [
          'uuid', //编号 20位 必须
          'moduleUuid', //名称 20位 必须
          'itemUuids', //身份证 必须
          'roleUuids', //电话号码
          'targetCodes', //人员类型 [枚举CustomerTypeEnum] 必须
        ],
        edit: [
          'uuid', //编号 20位 必须
          'moduleUuid', //名称 20位 必须
          'itemUuids', //身份证 必须
          'roleUuids', //电话号码
          'targetCodes', //人员类型 [枚举Custom
        ],
      }
    );
  },
  methods: {
    roleChangeAll(item, e) {
      const roleUuids = this.cruddataMX.data.roleUuids;
      const childrenListIds = item.children.map((val) => val.id);
      if (e) {
        if (roleUuids.length) {
          this.cruddataMX.data.roleUuids = roleUuids.concat(
            childrenListIds.filter((val) => !roleUuids.includes(val))
          );
        } else {
          this.cruddataMX.data.roleUuids = roleUuids.concat(childrenListIds);
        }
      } else {
        this.cruddataMX.data.roleUuids = roleUuids.filter((val) => {
          return !childrenListIds.includes(val);
        });
      }
    },
    roleItemIndeterminate(item) {
      const arr = this.cruddataMX.data.roleUuids;
      const itemArr = item.children.filter((val) => {
        return arr.includes(val.id);
      });
      console.log('name', item.name);
      console.log('arr', arr);
      console.log('length', itemArr);
      this.$nextTick(() => {
        item.checkAll =
          itemArr.length === item.children.length && itemArr.length > 0;
        // this.$set(
        //   item,
        //   'checkAll',
        //   itemArr.length === item.children.length && itemArr.length > 0
        // );
      });

      return itemArr.length > 0 && !item.checkAll;
    },
    async onOpenEdit(item, flag) {
      await this.getModuleList();
      await this.getRoleList();
      if (flag === '01') {
        await this.getModuleItems(item.moduleUuid);

        await this.$api.msgConfig.msgConfig
          .info(item)
          .then(({ code, data }) => {
            if (code === 200) {
              this.cruddataMX.data = data[0];
            }
          });
      }
    },
    moduleUuidChange(e) {
      if (!e) {
        this.cruddataMX.data.itemUuids = [];
      }
      this.getModuleItems(e);
    },
    async getModuleItems(moduleUuid) {
      if (!moduleUuid) {
        return;
      }
      this.$api.msgConfig.msgConfig
        .moduleItems({ moduleUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            const itemUuids = this.cruddataMX.data.itemUuids;
            if (this.cruddataMX.data.uuid) {
              this.cruddataMX.data.itemUuids = data.filter((item) => {
                return itemUuids.includes(item.value);
              });
            }
            this.moduleItemsList = data;
          }
        });
    },
    async getRoleList() {
      await this.$api.msgConfig.msgConfig.roleList().then(({ data, code }) => {
        if (code === 0) {
          this.roleList = data;
        }
      });
    },
    async getModuleList() {
      await this.$api.msgConfig.msgConfig
        .moduleList()
        .then(({ data, code }) => {
          if (code === 200) {
            this.moduleList = data;
          }
        });
    },
    getDict() {
      this.$api.msgConfig.msgConfig.dict().then(({ code, data }) => {
        if (code === 200) {
          this.dict = data[0];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
